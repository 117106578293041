// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import logo from "../images/2design-logo.png"

const StyledHeader = styled.header`
  padding-top: 100px;
`;


const Header = ({ siteTitle }) => (
  <StyledHeader>
    <img src={logo} alt="2design Logo" />
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
