import React from "react"
import styled from "styled-components"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../css/style.css";

const Heading = styled.h1`
  font-family: bree, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 40px;
  letter-spacing: 1px;
  line-height: 40px;
  text-transform: uppercase;
  color: #797979;
  margin: 127px 0px 126px;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <Heading>
      » Hier entsteht<br/>
      unsere neue Website «
    </Heading>

  </Layout>
)

export default IndexPage
