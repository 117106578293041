/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"


const Page = styled.section`
  margin: 0 auto;
  max-width: 1110px;
  text-align: center;
`;

const FooterHint = styled.footer`
  font-family: bree, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 24px;
  color: #797979;
  max-width: 540px;
  margin: 0 auto;  
`;
const Link = styled.a`
  color: #797979;
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Page>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <footer>
          <FooterHint>
            Eric Gröner • Auf den Rainen 16 • 72505 Hausen a.A.<br/>
            T +49 7576- 90 10 93 • F +49 07576- 90 11 17<br/>
            <Link href="mailto:info@2design.biz">info@2design.biz</Link>
          </FooterHint>
        </footer>
      </Page>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
