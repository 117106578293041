module.exports = [{
      plugin: require('/Users/marcokernler/Documents/denkfabrik-neueMedien/dev/web/20200121_2design-pre-site/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png"},
    },{
      plugin: require('/Users/marcokernler/Documents/denkfabrik-neueMedien/dev/web/20200121_2design-pre-site/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"yyv2vfv"}},
    },{
      plugin: require('/Users/marcokernler/Documents/denkfabrik-neueMedien/dev/web/20200121_2design-pre-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
